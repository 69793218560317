@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        padding-top: 50px;
    }
}

.background-image {
    background-image: url("./img/slider/s1.jpg");
    background-size: cover;
    background-attachment: fixed;
}

.wow-container {
    border-top: 5px solid #ec1f27;
    border-bottom: 1px solid #6a6a6a;
}

.wow-body-container {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.wow-header-text {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    color: black;
}

.uic-text-color {
    color: #ec1f27;
}

.uic-background-color {
    background-color: #ec1f27;
}
